.login-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-button {
  width: 90%; /* Hace que cada botón ocupe el ancho completo del contenedor */
  padding: 10px 0; /* Ajusta el relleno para cambiar la altura de los botones */
  margin: 5px 0; /* Añade un poco de espacio entre los botones */
  font-size: 16px; /* Ajusta el tamaño de la fuente según necesites */
  cursor: pointer; /* Cambia el cursor a un puntero al pasar por encima */
  border: none; /* Opcional: elimina el borde del botón */
  background-color: #007bff; /* Ajusta el color de fondo según necesites */
  color: white; /* Ajusta el color del texto según necesites */
  border-radius: 15px;
  text-decoration: none; /* Opcional: elimina el subrayado del texto */
  display: inline-block; /* Opcional: cambia el comportamiento del botón a "inline-block" */
  text-align: center; /* Opcional: centra el texto del botón */
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.login-button:hover {
  background-color: #54bb99;
}