.footer {
    background: gray;
    color: white;
    text-align: center;
    padding: 2px;
    position: fixed;
    bottom: 0;
    width: 100%;
}

/* Media query para pantallas de hasta 600px de ancho */
 @media (max-width: 600px) {
    .footer {
      font-size: 12px;
    }
  }

.footer .usuarioAutenticado {
    font-size: smaller;
}

@media (max-width: 600px) {
  .footer .usuarioAutenticado {
    font-size: 10px;
  }
}
