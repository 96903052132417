.MapInputContainer {
  display: flex;
  width: 70%;
  height: 4vh;
  flex-direction: row;
  margin: auto;
  margin-top: 0.25em;
  margin-bottom: 0.255em;

  @media (min-width: 601px) and (max-width: 1024px) and (orientation: landscape) {
    height: 8vh;
  }
}


.MapInputStyle {
  width: 60%;
  border: 1px solid #ccc;
  border-radius: 0.5em;
  padding: 0.25em;
  font-size: 1em;
  margin: auto;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.ButtonStyle {
  width: 10%;
  border-radius: 0.50em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  font-size: 1em;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  border: 1px solid white;
  background: transparent;
  cursor: pointer;
  color: white;
  outline: none;
  transition: color 0.2s;
}