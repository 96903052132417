.GoogleMapContainer {
  height: 87vh;
  width: 99%;
  margin: 0 auto;

  @media (max-width: 600px) and (orientation: portrait) {
    height: 81vh;
  }

  @media (min-width: 601px) and (max-width: 1024px) and (orientation: landscape) {
    height: 76vh;
  }
}