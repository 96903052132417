.boton-regresar {
    height: 2em;
    border-radius: 1em;
    margin:auto;
    margin-top: 0.25em;
    margin-bottom: 0em;
    display: flex;
    background-color: #4CAF50;
    color: white;
    width: 90%;
    font-size: 1em;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }
