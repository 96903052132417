* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  color: white;
  background-color: #1b1b32;
}

.App {
  display: flex;
  flex-direction: column; /* Alinea los botones verticalmente */
  min-height: 100vh; /* Esto asegura que `.App` ocupe al menos todo el viewport vertical */
}
